import { Component, OnInit } from '@angular/core';
import { Course, Union } from '../../types/models';
import { User } from '../../types/auth';
import { CoreService } from '../../services/core.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';

export interface Certificate {
  union: Union;
  course: Course;
  user: User;
  completion_date: string;
  certificate_mode: string;
}
@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss'],
})
export class CertificateComponent implements OnInit {
  public certificate: Certificate | undefined;
  public loading = true;
  public certificateDefaultMode = true;
  constructor(
    private coreService: CoreService,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    // Retrieve the slug from the URL
    const snapshot = this.activatedRoute.snapshot;
    const slug: string | null = snapshot.paramMap.get('slug');
    if (slug) {
      this.getCertificate(slug);
    }
  }

  public getCertificate(slug: string) {
    this.coreService.getCertificate(slug, {}).subscribe(
      (data) => {
        this.certificate = data;
        this.certificateDefaultMode = data.course.certificate_mode === 'V1';
        this.translate.use(this.certificate.course.language);
        this.loading = false;
      },
      (error) => {
        console.log(error);
        this.loading = false;
      }
    );
  }

  public format(dateString: string): string {
    const date = new Date(Date.parse(dateString));
    const intl = new Intl.DateTimeFormat(this.certificate?.course.language, {
      dateStyle: 'long',
    });
    return intl.format(date);
  }

  public downloadPDF(): void {
    let data = document.getElementById('certificate')
    if(!this.certificateDefaultMode) {
      data = document.getElementById('certificateV2');
    }
    const a4Width = 297;
    const a4Height = 210;
    if(data) {
      html2canvas(data).then(canvas => {
        let imgWidth = 280;
        let imgHeight = canvas.height * imgWidth / canvas.width;
        const contentDataURL = canvas.toDataURL('image/png')
        let pdf = new jspdf('l', 'mm', 'a4');
        let xPos = (a4Width - imgWidth) / 2;
        let yPos = (a4Height - imgHeight) / 2;
        pdf.addImage(contentDataURL, 'PNG', xPos, yPos, imgWidth, imgHeight)
        pdf.save('certificate.pdf');
      });
    }
  }
}
