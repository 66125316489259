<div class="container" *ngIf="loading">
  <ngx-skeleton-loader count="1" appearance="line" [theme]="
    {
      height: '500px',
      marginTop: '30px'
    }
"></ngx-skeleton-loader>
</div>
<div class="unit-results" *ngIf="!loading && response">
  <div class="unit-results__content container">
    <div class="unit-results__layout">
      <div class="unit-results__layout-side">
        <mat-icon class="unit-results__icon check-circle" *ngIf="percentage >= 80">check_circle</mat-icon>
        <mat-icon class="unit-results__icon cancel-circle" *ngIf="percentage < 80">cancel</mat-icon>
      </div>
      <div *ngIf="response" class="unit-results__layout-content">
        <app-section-header [title]="response.questionnaire.title" [unit]="response.unit.title"></app-section-header>
        <ng-container *ngIf="response.questionnaire.question_type === 'quiz'">
          <ng-container *ngIf="percentage >= 80">
            <div class="unit-results__message">
              {{"QUIZ_COMPLETED" | translate}}
            </div>
            <div class="completion-container">
              <p class="description" style="margin-top: 10px;">
                {{"QUIZ_COMPLETED_DESCRIPTION" | translate}}
              </p>
              <p class="description">
                {{"CORRECT_ANSWERS" | translate}}: {{correctAnswers}} / {{response.questionnaire.questions.length}}
              </p>
              <a class="link" (click)="onOpenModal()">{{"VIEW_RESPONSE_DETAILS" | translate}}
                <mat-icon>arrow_forward</mat-icon>
              </a>
            </div>
          </ng-container>
          <ng-container *ngIf="percentage < 80">
            <div class="unit-results__message">
              {{"QUIZ_ASSESSMENT_FAILED" | translate}}
            </div>
            <div class="completion-container">
              <p class="description" style="margin-top: 10px;">
                {{"QUIZ_ASSESSMENT_FAILED_DESCRIPTION" | translate}}
              </p>
              <p class="description">
                {{"CORRECT_ANSWERS" | translate}}: {{correctAnswers}} / {{response.questionnaire.questions.length}}
              </p>
              <a class="link" (click)="onOpenModal()">{{"VIEW_RESPONSE_DETAILS" | translate}}
                <mat-icon>arrow_forward</mat-icon>
              </a>
            </div>
          </ng-container>
        </ng-container>
        <!-- Footer -->
        <ng-container *ngIf="response.questionnaire.question_type === 'quiz'">
          <div class="unit-results__submit noprint" *ngIf="percentage >= 80">
            <button mat-button color="primary"
              [routerLink]="['/courses/' + response.unit.course.slug + '/']">{{"VIEW_COURSE_OVERVIEW" | translate}}
            </button>
            <a class="link" mat-button color="primary" *ngIf="response.next_questionnaire"
              [routerLink]="['/surveys/' + response.next_questionnaire.slug + '/']">{{"CONTINUE_TO" | translate}}
              {{response.next_questionnaire.title}}
            </a>
            <a mat-button class="link" *ngIf="response.next_unit && !response.next_questionnaire"
              [routerLink]="['/lessons/' + response.next_unit + '/']">{{"NEXT_UNIT" | translate}}
            </a>
          </div>
          <div class="unit-results__submit noprint" *ngIf="percentage < 80">
            <button mat-button color="primary"
              [routerLink]="['/surveys/' + response.questionnaire.slug + '/']">{{"RETAKE_QUIZ" | translate}}
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="response.questionnaire.question_type === 'survey'">
          <div class="unit-results__submit noprint">
            <button mat-button color="primary"
              [routerLink]="['/courses/' + response.unit.course.slug + '/']">{{"VIEW_COURSE_OVERVIEW" | translate}}
            </button>
            <a class="link" mat-button color="primary" *ngIf="response.next_questionnaire"
              [routerLink]="['/surveys/' + response.next_questionnaire.slug + '/']">{{"CONTINUE_TO" | translate}}
              {{response.next_questionnaire.title}}
            </a>
            <a mat-button class="link" *ngIf="response.next_unit && !response.next_questionnaire"
              [routerLink]="['/lessons/' + response.next_unit + '/']">{{"NEXT_UNIT" | translate}}
            </a>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #answersModal let-data>
  <div class="question" *ngFor="let question of response?.questionnaire?.questions; let i = index;">
    <h3 class="title bold">{{i + 1}}. {{question.title}}</h3>
    <div *ngIf="!isAnswerCorrect(question)">

      <h4 class="answer">{{"YOUR_ANSWER" | translate}}:
        <div *ngIf="isAnswerSlugArray(question)">
          <div class="question-response" *ngFor="let response of getUserAnswers(question.slug); let i = index;">
            <span class="bold">{{response}}</span>
            <br />
          </div>
        </div>
        <div *ngIf="!isAnswerSlugArray(question)">
          <span class="bold">{{getUserAnswer(question.slug)}}</span>
        </div>
      </h4>
    </div>

    <h4 class="answer">{{"CORRECT_ANSWER" | translate}}:
      <div *ngIf="isAnswerSlugArray(question)">
        <div class="question-answer" *ngFor="let response of getCorrectAnswers(question.slug); let i = index;">
          <span class="bold">{{response}}</span>
          <br />
        </div>
      </div>
      <div *ngIf="!isAnswerSlugArray(question)">
        <div *ngIf="!showAnswer"> <span> {{getCorrectAnswer(question.slug) === getUserAnswer(question.slug) ? "Right Answer" : "Wrong Answer"}} </span> </div>
        <div *ngIf="showAnswer"> <span class="bold">{{getCorrectAnswer(question.slug)}}</span> </div>
      </div>
    </h4>
  </div>
</ng-template>