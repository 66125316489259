import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreService } from '../../services/core.service';
import {
  Question,
  QuestionAnswer,
  Questionnaire,
  QuestionOption,
} from '../../types/models';
import { Title } from '@angular/platform-browser';
import { UiService } from '../../services/ui.service';
import { MatRadioChange } from '@angular/material/radio';
import { MatCheckboxChange } from '@angular/material/checkbox';
import {UnionService} from "../../services/union.service";
import { TranslateService } from '@ngx-translate/core';
import {
  CdkDrag,
  CdkDragDrop,
  CdkDragPlaceholder,
  CdkDropList,
  moveItemInArray,
} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-feedback-survey',
  templateUrl: './feedback-survey.component.html',
  styleUrls: ['./feedback-survey.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FeedbackSurveyComponent implements OnInit, OnDestroy {
  public loading = true;
  public questionnaire: Questionnaire | undefined;
  public answers: QuestionAnswer[] = [];
  public sections: any[] = [];
  public answeredQuestions: string[] = [];
  public nextNavigationControl: any;
  public previousNavigatorControl = {
    title: this.translate.instant('COURSE_OVERVIEW'),
    label: this.translate.instant('GO_BACK'),
    url: '/',
    buttonText: this.translate.instant('CONTINUE'),
  };
  private observer: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private coreService: CoreService,
    private uiService: UiService,
    private title: Title,
    private router: Router,
    private unionService: UnionService,
    private translate: TranslateService
  ) {
    this.title.setTitle(`Feedback survey - Google Workspace Training`);
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  ngOnInit(): void {
    // Retrieve the course slug from the URL
    const snapshot = this.activatedRoute.snapshot;
    const slug: string | null = snapshot.paramMap.get('slug');

    if (slug) {
      this.onLoadQuestionnaire(slug);
    }
  }

  /* Load the course */
  public onLoadQuestionnaire(slug: string): void {
    this.coreService.getQuestionnaire(slug).subscribe(
      (questionnaire) => {
        this.questionnaire = questionnaire;
        this.sections = this.questionnaire.questions.map((x, index) => {
          const payload = {
            title: `${this.translate.instant('QUESTION')} ${index + 1}`,
            slug: `${x.slug}`,
          };
          return payload;
        });
        // Set the tab title based on the question type
        const title = this.questionnaire.question_type === 'quiz' 
          ? 'Quiz - Google Workspace Training' 
          : 'Feedback survey - Google Workspace Training';
        this.title.setTitle(title);
  
        const courseSlug = this.questionnaire.unit.course.slug;
        this.previousNavigatorControl.url = `/courses/${courseSlug}`;
        
        if (this.questionnaire.next_unit) {
          this.nextNavigationControl = {
            title: this.translate.instant('START_LESSON'),
            label: this.translate.instant('NEXT_LESSON'),
            url: '/lessons/' + this.questionnaire.next_unit,
            buttonText: this.translate.instant('CONTINUE'),
          };
        }
        this.loading = false;
        document.getElementById('drawer')?.scrollIntoView();
  
        /* Update the active section as the user scrolls along the app */
        setTimeout(() => {
          const threshold = 0.5; // 50%
          this.observer = new IntersectionObserver(
            (entries) => {
              let latestEntry: any = null;
              let latestIndex = 0;
              entries.forEach((entry, index) => {
                if (entry.isIntersecting) {
                  if (entry.boundingClientRect.y <= 500) {
                    this.uiService.sectionClicked.emit(entry.target.id);
                    latestEntry = entry;
                    latestIndex = index;
                  }
                } else {
                  // If it's less than viewport then jump to next entry
                  if (entry.boundingClientRect.y <= 100) {
                    this.uiService.nextSection.emit(true);
                  }
                }
              });
            },
            { threshold }
          );
          const blockList = document.querySelectorAll('.block-text');
          blockList.forEach((block) => {
            this.observer.observe(block);
          });
        }, 0);
      },
      () => {
        this.loading = false;
      }
    );
  }

  checkDragDropAnswer(myOrder: number[]) {
    let initOrder: any = [];
    myOrder.map((item, i) => {
      initOrder.push(i + 1);
    }) 
    return initOrder.every((value, index) => value === myOrder[index]);
  }

  drop(event: CdkDragDrop<string[]>, i: number) {
    if(this.questionnaire) {
      let myOrder: any = [];
      moveItemInArray(this.questionnaire.questions[i].options, event.previousIndex, event.currentIndex);
      this.questionnaire.questions[i].options.map((option, index) => {
        myOrder.push(option.order)
      })
      const isExist = this.answers.find((answer) => answer.question_slug == this.questionnaire?.questions[i].slug);
      const isCorrect = this.checkDragDropAnswer(myOrder)
      if(isExist) {
        this.answers.map((answer, index) => {
          if(answer.answer_slug === isExist.answer_slug) {
            answer.answer_slug = myOrder.join(',');
            answer.correct = this.checkDragDropAnswer(myOrder)
          }
        })
        return;
      }
      this.answers.push({answer_slug:myOrder.join(','),question_slug:this.questionnaire?.questions[i].slug, correct:isCorrect});
      this.answeredQuestions.push(this.questionnaire?.questions[i].slug);
    }
  }


  public onSubmitQuestionnaire(questionnaire): void {
    const payload = {
      questionnaire: this.questionnaire?.slug,
      answers: this.answers,
      union: this.unionService.union?.slug
    };
    this.coreService.createResponse(payload).subscribe((res) => {
      this.router.navigate(['/responses/' + res.slug + '/']);
    });
  }

  /* Scroll to section */
  public scrollTo(section: string): void {
    const element = document.getElementById(section);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  /* Option selected */
  public onSelect(question: Question, e: MatRadioChange) {
    const value = e.value;
    const option = question.options.find((x) => {
      return x.slug === value;
    });
    /* If value provided doesn't exist then ignore this rule */
    if (!option) {
      return;
    }
    const answerIndex = this.answers.findIndex((x) => {
      return x.question_slug === question.slug;
    });
    /* If answer already exists then replace it with the value from this entry */
    if (answerIndex > -1) {
      this.answers[answerIndex].answer_slug = value;
      this.answers[answerIndex].correct = option.correct;
    } else {
      /* Otherwise create a new answer */
      const answer: QuestionAnswer = {
        question_slug: question.slug,
        answer_slug: value,
        correct: option.correct,
      };
      this.answers.push(answer);
    }
    /* Check if question has already been answered */
    const questionIndex = this.answeredQuestions.findIndex(
      (x) => x === question.slug
    );
    if (!(questionIndex > -1)) {
      this.answeredQuestions.push(question.slug);
    }
  }

  /* Checklist selected */
  public onChecklistSelect(
    question: Question,
    e: MatCheckboxChange,
    option: QuestionOption
  ) {

    
    // Find if this option is already in the list of answers
    const answerIndex = this.answers.findIndex((x) => {
      return x.question_slug === question.slug;// && x.answer_slug === option.slug
    });
    const prevAnswer = {...(this.answers?.[answerIndex] ?? {})}
    let updatedAnswer: QuestionAnswer;

    /* If answer already exists then replace it then remove it */
    if (answerIndex > -1) {

      const newAnswer_slug = e.checked ? [...prevAnswer.answer_slug, option.slug] : (prevAnswer.answer_slug as string[]).filter(e => e !== option.slug)

      updatedAnswer = {
        ...prevAnswer,
        answer_slug: newAnswer_slug
      };
      this.answers.splice(answerIndex, 1, updatedAnswer);
      
    } else {
      /* Otherwise create a new answer */
      updatedAnswer = {
        question_slug: question.slug,
        answer_slug: [option.slug],
        correct: option.correct,
      };
      this.answers.push(updatedAnswer);
    }

    let correct = true;
    for(const option of question.options){
      const answeredTrue = updatedAnswer.answer_slug.includes(option.slug);
      if(option.correct !== answeredTrue){
        correct = false;
      }
    }
    updatedAnswer.correct = correct

    /* Check if question has already been answered */
    const questionIndex = this.answeredQuestions.findIndex(
      (x) => x === question.slug
    );
    if (!(questionIndex > -1)) {
      this.answeredQuestions.push(question.slug);
    }
  }
}