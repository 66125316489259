<div class="certificate-container">
  <div class="container" *ngIf="loading">
      <ngx-skeleton-loader count="1" appearance="line" [theme]="
      {
        height: '500px',
        marginTop: '30px'
      }
  "></ngx-skeleton-loader>
  </div>
  <div class="container" *ngIf="!loading && certificate">
    <ng-container
      *ngIf="certificateDefaultMode; then certificateV1; else certificateV2">
    </ng-container>
    <ng-template #certificateV1>
      <div id="certificate">
        <div class="header">
          <div class="logo">
            <img src="/assets/img/google-logo.svg" alt="">
          </div>
          <div class="union">
            <img src="{{certificate.union.logo_url}}" alt="">
          </div>
        </div>
        <div class="body">
          <h1 class="title">{{"CONGRATULATIONS" | translate}}</h1>
          <div class="subtitle">{{certificate.user.first_name}} {{certificate.user.last_name}}</div>
          <img src="/assets/img/cert.png" class="cert" alt="">
          <div class="image-text">{{"CERTIFICATE_COMPLETE" | translate}}</div>
          <div class="course-title">{{certificate.course.title}}</div>
          <p class="date">{{format(certificate.completion_date)}}</p>
        </div>
      </div>
    </ng-template>
    <ng-template #certificateV2>
      <div id="certificateV2" class="image-container">
        <img src="/assets/img/colorful-certificate.png" alt="image" class="image">
        <div class="name">{{certificate.user.first_name}}</div>
        <div class="title">{{certificate.course.title}}</div>
        <div class="date">{{format(certificate.completion_date)}}</div>
      </div>
    </ng-template>
  </div>
  <div class="container justify-center download-btn" *ngIf="!loading && certificate">
    <button mat-raised-button (click)="downloadPDF()">Download certificate</button>
  </div>
</div>


