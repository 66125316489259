import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LTIAuthResponse, Payload } from '../types/payload';
import {
  Collection,
  Course,
  CourseAssessment,
  Language,
  LanguageI18nModel,
  Lesson,
  Question,
  Questionnaire,
  Response,
  Tag,
  Union,
  Unit,
} from '../types/models';
import { Certificate } from '../pages/certificate/certificate.component';

@Injectable({
  providedIn: 'root',
})
export class CoreService {
  public baseUrl: string;

  showAnswer: string = localStorage.getItem('show_answer') ?? 'true';
  private showAnswerSubject = new Subject<string>();

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiBaseUrl;
  }

  setShowAnswer(value: string) {
    this.showAnswer = value;
    localStorage.setItem('show_answer', value);
    this.showAnswerSubject.next(value);
  }

  getShowAnswer() {
    return this.showAnswer;
  }

  getShowAnswerSubject(): Observable<string> {
    return this.showAnswerSubject.asObservable();
  }

  public getCertificate(slug, params: {}): Observable<Certificate> {
    const url =
      `${this.baseUrl}/${environment.endpoints.api.certificate}`.replace(
        '{}',
        slug
      );
    return this.http.get<Certificate>(url, params);
  }


  public getLTIAuthResponse(slug, params: {}): Observable<LTIAuthResponse> {
    const url = `${this.baseUrl}/${environment.endpoints.api.lti}`.replace(
      '{}',
      slug
    );
    return this.http.get<LTIAuthResponse>(url, params);
  }

  public getLTIAccessToken(payload: any): Observable<any> {
    const url = `${this.baseUrl}/${environment.endpoints.api.ltiAccessToken}`;
    return this.http.post<any>(url, payload);
  }

  public getUnions(params: any): Observable<Payload<Union>> {
    const url = `${this.baseUrl}/${environment.endpoints.api.unions}`;
    return this.http.get<Payload<Union>>(url, { params });
  }

  public createUnion(payload: any): Observable<Union> {
    const url = `${this.baseUrl}/${environment.endpoints.api.unions}`;
    return this.http.post<Union>(url, payload);
  }

  public getUnion(slug: string): Observable<Union> {
    const url = `${this.baseUrl}/${environment.endpoints.api.union}`.replace(
      '{}',
      slug
    );
    return this.http.get<Union>(url);
  }

  public getUnionBySubdomain(subdomain: string): Observable<Union> {
    const url =
      `${this.baseUrl}/${environment.endpoints.api.unionBySubdomain}`.replace(
        '{}',
        subdomain
      );
    return this.http.get<Union>(url);
  }

  public updateUnion(payload: any, slug: string): Observable<Union> {
    const url = `${this.baseUrl}/${environment.endpoints.api.union}`.replace(
      '{}',
      slug
    );
    return this.http.patch<Union>(url, payload);
  }

  public deleteUnion(slug: string): Observable<Union> {
    const url = `${this.baseUrl}/${environment.endpoints.api.union}`.replace(
      '{}',
      slug
    );
    return this.http.delete<Union>(url, {});
  }

  public getCollections(params: any): Observable<Payload<Collection>> {
    const url = `${this.baseUrl}/${environment.endpoints.api.collections}`;
    return this.http.get<Payload<Collection>>(url, { params });
  }

  public createCollection(payload: any): Observable<Collection> {
    const url = `${this.baseUrl}/${environment.endpoints.api.collections}`;
    return this.http.post<Collection>(url, payload);
  }

  public getCollection(slug: string): Observable<Collection> {
    const url =
      `${this.baseUrl}/${environment.endpoints.api.collection}`.replace(
        '{}',
        slug
      );
    return this.http.get<Collection>(url);
  }

  public updateCollection(payload: any, slug: string): Observable<Collection> {
    const url =
      `${this.baseUrl}/${environment.endpoints.api.collection}`.replace(
        '{}',
        slug
      );
    return this.http.patch<Collection>(url, payload);
  }

  public deleteCollection(slug: string): Observable<Collection> {
    const url =
      `${this.baseUrl}/${environment.endpoints.api.collection}`.replace(
        '{}',
        slug
      );
    return this.http.delete<Collection>(url, {});
  }

  public getCourseAssessments(
    params: any
  ): Observable<Payload<CourseAssessment>> {
    const url = `${this.baseUrl}/${environment.endpoints.api.courseAssessments}`;
    return this.http.get<Payload<CourseAssessment>>(url, { params });
  }

  public createCourseAssessment(payload: any): Observable<CourseAssessment> {
    const url = `${this.baseUrl}/${environment.endpoints.api.courseAssessments}`;
    return this.http.post<CourseAssessment>(url, payload);
  }

  public getCourseAssessment(slug: string): Observable<CourseAssessment> {
    const url =
      `${this.baseUrl}/${environment.endpoints.api.courseAssessment}`.replace(
        '{}',
        slug
      );
    return this.http.get<CourseAssessment>(url);
  }

  public updateCourseAssessment(
    payload: any,
    slug: string
  ): Observable<CourseAssessment> {
    const url =
      `${this.baseUrl}/${environment.endpoints.api.courseAssessment}`.replace(
        '{}',
        slug
      );
    return this.http.patch<CourseAssessment>(url, payload);
  }

  public deleteCourseAssessment(slug: string): Observable<CourseAssessment> {
    const url =
      `${this.baseUrl}/${environment.endpoints.api.courseAssessment}`.replace(
        '{}',
        slug
      );
    return this.http.delete<CourseAssessment>(url, {});
  }

  public getCourses(params: any): Observable<Payload<Course>> {
    const url = `${this.baseUrl}/${environment.endpoints.api.courses}`;
    return this.http.get<Payload<Course>>(url, { params });
  }

  public createCourse(payload: any): Observable<Course> {
    const url = `${this.baseUrl}/${environment.endpoints.api.courses}`;
    return this.http.post<Course>(url, payload);
  }

  public getCourse(slug: string, params = {}): Observable<Course> {
    const url = `${this.baseUrl}/${environment.endpoints.api.course}`.replace(
      '{}',
      slug
    );
    return this.http.get<Course>(url, { params });
  }

  public getCourseByLanguage(
    slug: string,
    countryCode: string,
    params = {}
  ): Observable<Course> {
    const url = `${this.baseUrl}/${environment.endpoints.api.courseLanguage}`
      .replace('{}', slug)
      .replace('{code}', countryCode);
    return this.http.get<Course>(url, { params });
  }

  public updateCourse(payload: any, slug: string): Observable<Course> {
    const url = `${this.baseUrl}/${environment.endpoints.api.course}`.replace(
      '{}',
      slug
    );
    return this.http.patch<Course>(url, payload);
  }

  public deleteCourse(slug: string): Observable<Course> {
    const url = `${this.baseUrl}/${environment.endpoints.api.course}`.replace(
      '{}',
      slug
    );
    return this.http.delete<Course>(url, {});
  }

  public getUnits(params: any): Observable<Payload<Unit>> {
    const url = `${this.baseUrl}/${environment.endpoints.api.units}`;
    return this.http.get<Payload<Unit>>(url, { params });
  }

  public createUnit(payload: any): Observable<Unit> {
    const url = `${this.baseUrl}/${environment.endpoints.api.units}`;
    return this.http.post<Unit>(url, payload);
  }

  public getUnit(slug: string): Observable<Unit> {
    const url = `${this.baseUrl}/${environment.endpoints.api.unit}`.replace(
      '{}',
      slug
    );
    return this.http.get<Unit>(url);
  }

  public updateUnit(payload: any, slug: string): Observable<Unit> {
    const url = `${this.baseUrl}/${environment.endpoints.api.unit}`.replace(
      '{}',
      slug
    );
    return this.http.patch<Unit>(url, payload);
  }

  public deleteUnit(slug: string): Observable<Unit> {
    const url = `${this.baseUrl}/${environment.endpoints.api.unit}`.replace(
      '{}',
      slug
    );
    return this.http.delete<Unit>(url, {});
  }

  public getLessons(params: any): Observable<Payload<Lesson>> {
    const url = `${this.baseUrl}/${environment.endpoints.api.lessons}`;
    return this.http.get<Payload<Lesson>>(url, { params });
  }

  public createLesson(payload: any): Observable<Lesson> {
    const url = `${this.baseUrl}/${environment.endpoints.api.lessons}`;
    return this.http.post<Lesson>(url, payload);
  }

  public getLesson(slug: string): Observable<Lesson> {
    const url = `${this.baseUrl}/${environment.endpoints.api.lesson}`.replace(
      '{}',
      slug
    );
    return this.http.get<Lesson>(url);
  }

  public markLessonAsRead(slug: string, payload): Observable<Lesson> {
    const url =
      `${this.baseUrl}/${environment.endpoints.api.lessonRead}`.replace(
        '{}',
        slug
      );
    return this.http.post<Lesson>(url, payload);
  }

  public updateLesson(payload: any, slug: string): Observable<Lesson> {
    const url = `${this.baseUrl}/${environment.endpoints.api.lesson}`.replace(
      '{}',
      slug
    );
    return this.http.patch<Lesson>(url, payload);
  }

  public deleteLesson(slug: string): Observable<Lesson> {
    const url = `${this.baseUrl}/${environment.endpoints.api.lesson}`.replace(
      '{}',
      slug
    );
    return this.http.delete<Lesson>(url, {});
  }

  public getTags(params: any): Observable<Payload<Tag>> {
    const url = `${this.baseUrl}/${environment.endpoints.api.tags}`;
    return this.http.get<Payload<Tag>>(url, { params });
  }

  public createTag(payload: any): Observable<Tag> {
    const url = `${this.baseUrl}/${environment.endpoints.api.tags}`;
    return this.http.post<Tag>(url, payload);
  }

  public getTag(slug: string): Observable<Tag> {
    const url = `${this.baseUrl}/${environment.endpoints.api.tag}`.replace(
      '{}',
      slug
    );
    return this.http.get<Tag>(url);
  }

  public updateTag(payload: any, slug: string): Observable<Tag> {
    const url = `${this.baseUrl}/${environment.endpoints.api.tag}`.replace(
      '{}',
      slug
    );
    return this.http.patch<Tag>(url, payload);
  }

  public deleteTag(slug: string): Observable<Tag> {
    const url = `${this.baseUrl}/${environment.endpoints.api.tag}`.replace(
      '{}',
      slug
    );
    return this.http.delete<Tag>(url, {});
  }

  public getQuestionnaires(params: any): Observable<Payload<Questionnaire>> {
    const url = `${this.baseUrl}/${environment.endpoints.api.questionnaires}`;
    return this.http.get<Payload<Questionnaire>>(url, { params });
  }

  public createQuestionnaire(payload: any): Observable<Questionnaire> {
    const url = `${this.baseUrl}/${environment.endpoints.api.questionnaires}`;
    return this.http.post<Questionnaire>(url, payload);
  }

  public getQuestionnaire(slug: string): Observable<Questionnaire> {
    const url =
      `${this.baseUrl}/${environment.endpoints.api.questionnaire}`.replace(
        '{}',
        slug
      );
    return this.http.get<Questionnaire>(url);
  }

  public updateQuestionnaire(
    payload: any,
    slug: string
  ): Observable<Questionnaire> {
    const url =
      `${this.baseUrl}/${environment.endpoints.api.questionnaire}`.replace(
        '{}',
        slug
      );
    return this.http.patch<Questionnaire>(url, payload);
  }

  public deleteQuestionnaire(slug: string): Observable<Questionnaire> {
    const url =
      `${this.baseUrl}/${environment.endpoints.api.questionnaire}`.replace(
        '{}',
        slug
      );
    return this.http.delete<Questionnaire>(url, {});
  }

  public getQuestions(params: any): Observable<Payload<Question>> {
    const url = `${this.baseUrl}/${environment.endpoints.api.questions}`;
    return this.http.get<Payload<Question>>(url, { params });
  }

  public createQuestion(payload: any): Observable<Question> {
    const url = `${this.baseUrl}/${environment.endpoints.api.questions}`;
    return this.http.post<Question>(url, payload);
  }

  public getQuestion(slug: string): Observable<Question> {
    const url = `${this.baseUrl}/${environment.endpoints.api.question}`.replace(
      '{}',
      slug
    );
    return this.http.get<Question>(url);
  }

  public updateQuestion(payload: any, slug: string): Observable<Question> {
    const url = `${this.baseUrl}/${environment.endpoints.api.question}`.replace(
      '{}',
      slug
    );
    return this.http.patch<Question>(url, payload);
  }

  public deleteQuestion(slug: string): Observable<Question> {
    const url = `${this.baseUrl}/${environment.endpoints.api.question}`.replace(
      '{}',
      slug
    );
    return this.http.delete<Question>(url, {});
  }

  public createResponse(payload: any): Observable<Response> {
    const url = `${this.baseUrl}/${environment.endpoints.api.responses}`;
    return this.http.post<Response>(url, payload);
  }

  public getResponse(slug: string): Observable<Response> {
    const url = `${this.baseUrl}/${environment.endpoints.api.response}`.replace(
      '{}',
      slug
    );
    return this.http.get<Response>(url);
  }

  public getLanguages(): Observable<Language[]> {
    const url = `${this.baseUrl}/${environment.endpoints.api.languages}`;
    return this.http.get<Language[]>(url);
  }

  public getLanguageI18nModel(isoCode: string): Observable<LanguageI18nModel> {
    const url = `${this.baseUrl}/${environment.endpoints.api.language}`.replace(
      '{}',
      isoCode
    );
    return this.http.get<LanguageI18nModel>(url);
  }
}
