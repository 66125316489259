<div class="quiz" *ngIf="block" id="{{block.slug}}">
  <h1>{{block.title}}</h1>

  <ng-container *ngIf="block.questionnaire">
    <div class="quiz-item" *ngFor="let question of block?.questionnaire?.questions" >
      <div class="quiz-title">{{question.title}}</div>
      <mat-radio-group class="items-container">
        <mat-radio-button class="quiz-answer" *ngFor="let option of question.options; let i = index" [value]="option.slug" (change)="onSelect(question, option)">
          <div class="quiz-answer-label">{{option.name}}</div>
        </mat-radio-button>
      </mat-radio-group>
      <button mat-button color="primary" class="quiz-btn" [disabled]="question.selectedOption === undefined" (click)="onCheckAnswer(question)">{{"Submit" | translate}}</button>
    </div>
  </ng-container>
</div>

<ng-template #correctAnswer let-data>
  <mat-card *ngIf="data.showAnswer; else hideAnswer">
    <h2 class="title">{{data.question.title}}</h2>
    <h4 class="answer">{{"CORRECT_ANSWER" | translate}}:</h4>
    <p class="description">{{data.correctOption.name}}</p>
  </mat-card>
  <ng-template #hideAnswer>
    <mat-card>
      <h2 class="title">{{data.question.title}}</h2>
      <h4 class="answer" 
        [ngClass]="{ 'right-answer': data.correct, 'wrong-answer': !data.correct }">
          {{data.correct ? "Right Answer" : "Wrong Answer"}}
      </h4>
    </mat-card>
  </ng-template>
</ng-template>



